class FallingEmojis {
    constructor(emojis, container) {
      this.emojis = emojis;
      this.container = container;
      this.createEmoji();
    }
  
    createEmoji() {
      const emojiElement = document.createElement('div');
      emojiElement.classList.add('emoji');
      // Select a random emoji
      emojiElement.textContent = this.emojis[Math.floor(Math.random() * this.emojis.length)];
      this.container.appendChild(emojiElement);
  
      const size = Math.random() * 3 + 1; // Random size between 1 and 4
      const posX = Math.random() * window.innerWidth;
  
      Object.assign(emojiElement.style, {
        left: `${posX}px`,
        fontSize: `${size}rem`,
        animationDuration: `${Math.random() * 5 + 5}s`, // Slower, random duration between 5 and 10 seconds
      });
  
      setTimeout(() => emojiElement.remove(), 10000); // Remove emoji after 10 seconds
    }
  }
  
  // Export the function to be used in React
  export default function initFallingEmojis() {
    const container = document.querySelector('.emoji-container');
    if (!container) return;  // Ensure container exists
  
    const emojis = ['👺', '⛔', '💀' , '☢️', '💩']; // Add more emojis as desired
    setInterval(() => {
      new FallingEmojis(emojis, container);
    }, 600); // New emoji every 600 milliseconds for less frequent falling
  }
  