import React from 'react';
import './Popup.css';

const Popup = ({ onClose, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content scrollbar-style"> {/* Here's where you add the class */}
        <button onClick={onClose} className="close-popup">X</button>
        <h2 className="popup-title">Welcome to Grinch AI</h2>
        <p className="popup-text">
          DETAILS:
        </p>
        <p className="popup-text">
          <strong>SMART CONTRACT:</strong> PLEASEHOLDER CONTRACT<br/>
          <strong>LP LOCK:</strong> 🔒 | <strong>CONTRACT:</strong> <a href="[link]" target="_blank">[link]</a>
        </p>
      </div>
    </div>
  );
};

export default Popup;
