import React, { useEffect, useState } from 'react';
import './background.css'; // Ensure this is imported
import initFallingEmojis from './background.js';
import AudioPlayer from './AudioPlayer';
import Popup from './Popup';
import logo from './logo.png';
import './App.css';

const App = () => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Initialize the falling emojis when the component mounts
    initFallingEmojis();
  }, []);

  const handleUserInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  return (
    <div className="app-container" onClick={handleUserInteraction} onScroll={handleUserInteraction}>
      {/* Emoji container */}
      <div className="emoji-container"></div>

      {hasInteracted && <AudioPlayer src="/audio/song1.mp3" />}
      <img src={logo} alt="Logo" className="logo" />
      <header className="header-content">
        <h1>Grinch AI</h1>
        <h2> Source of Anti Christmas </h2>
        <p>Hee Hee Hoe!! Fuck off Christmas! Are you ready to outwit the market?</p>
      </header>
      <button className="connect-wallet">Connect Wallet</button>
      <div className="buttons-container">
        <button className="buy-now">Buy Now</button>
        <button className="chart">Chart</button>
        <button className="learn-more" onClick={() => setShowPopup(true)}>Learn More</button>
      </div>
      <Popup isVisible={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  );
};

export default App;
